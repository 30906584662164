<template>
  <el-container>
    <el-row justify="center" type="flex">
      <el-col>
        <div v-if="isGranted('ROLE_MANAGER')">
          <h3>{{$t("index.manager.title")}}</h3>
          <p> {{$t("index.manager.body")}} </p>
        </div>
        <div v-else-if="basicUser">
          <dashboard-basic-user v-bind:user="appUser.id"></dashboard-basic-user>
        </div>
        <div v-else>
          <dashboard-supplier-user v-bind:user="appUser.id"></dashboard-supplier-user>
        </div>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
import DashboardBasicUser from "@/components/DashboardBasicUser";
import DashboardSupplierUser from "@/components/DashboardSupplierUser";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "DashboardIndex",
  components: { DashboardBasicUser, DashboardSupplierUser },
  data() {
    return { basicUser: false };
  },
  computed: {
    ...mapGetters(["appUser", "currentUser", "isGranted"]),
  },
  methods: {
    ...mapActions(["fetchUser"]),
  },
  async created() {
    await this.fetchUser(this.appUser.id);

    this.basicUser = this.currentUser.type === "user";
  },
};
</script>

<style scoped></style>
