<template>
  <div v-if="currentUser">
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span>I miei dati</span>
        </div>
      </template>
      <el-avatar
          shape="square"
          :size="200"
          :src="src"
          fit="contain"
      >
      </el-avatar>
      <el-descriptions class="margin-top" :column="3" :size="size" border>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-user"></i>
            Ragione sociale
          </template>
          {{ getFullName(currentUser) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <i class="el-icon-location-outline"></i>
            Indirizzo
          </template>
          {{ stringifyAddress(currentUser.address)}}
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
  </div>
</template>

<script>

import UserMixin from "@/mixins/UserMixin";
import AddressMixin from "@/mixins/AddressMixin";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "DashboardBasicUser",
  mixins: [UserMixin, AddressMixin],
  props: ["user"],

  data() {
    return {
      src: " ",
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    ...mapActions(["fetchUser"]),
  },
  async created() {
    await this.fetchUser(this.user);
    this.src = this.currentUser.imgData || "logo_default_sunny.PNG";
  }
}
</script>

<style scoped>

</style>

